import React, { useState, useEffect } from "react";
import { EuiTitle, EuiDatePickerRange, EuiDatePicker } from "@elastic/eui";

const DateRangePicker = ({ onChange, label }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (startDate && endDate) {
      const startUTC = new Date(startDate).toISOString();
      const endUTC = new Date(endDate).toISOString();

      onChange({
        dateMin: startUTC,
        dateMax: endUTC,
      });
    }
  }, [startDate, endDate, onChange]);

  const isInvalid = startDate && endDate && startDate > endDate;

  return (
    <div data-testid="overridable-date-range-facet">
      <EuiTitle size="xxs">
        <h3>{label}</h3>
      </EuiTitle>
      <EuiDatePickerRange
        startDateControl={
          <EuiDatePicker
            selected={startDate}
            onChange={setStartDate}
            startDate={startDate}
            endDate={endDate}
            isInvalid={isInvalid}
            placeholder="From"
            aria-label="Start date"
          />
        }
        endDateControl={
          <EuiDatePicker
            selected={endDate}
            onChange={setEndDate}
            startDate={startDate}
            endDate={endDate}
            isInvalid={isInvalid}
            placeholder="To"
            aria-label="End date"
          />
        }
      />
    </div>
  );
};

export default DateRangePicker;

import React, { useState } from "react";
import { EuiFieldSearch } from "@elastic/eui";

const SearchBar = ({
  id,
  label = "",
  placeholder = "Search",
  isLoading = false,
  isClearable = true,
  onSearch,
  onChange,
  ariaLabel = "Search",
}) => {
  const [query, setQuery] = useState("");

  const handleSearch = (value) => {
    setQuery(value);
    if (onSearch) onSearch(value);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (onChange) onChange(value);
  };

  return (
    <>
      <span>{label}</span>
      <EuiFieldSearch
        id={id}
        role={"searchbox"}
        placeholder={placeholder}
        value={query}
        onChange={handleChange}
        onSearch={handleSearch}
        isLoading={isLoading}
        isClearable={isClearable}
        aria-label={ariaLabel}
      />
    </>
  );
};

export default SearchBar;
